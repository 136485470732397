import { create } from 'zustand';
import { getUserInfo, getUserSets } from 'graphql/user';
import { isDev } from 'utils';
import { User, UserStatistics, Trophy, UserSetCard } from './user.types';
import { getUserMock, getUserStatsMock, trophyListMock, userSetsMock } from './user.mocks';

interface UserState {
  user: User | null;
  statistics: UserStatistics | null;
  trophies: Trophy[] | null;
  sets: UserSetCard[] | null;
}

interface UserActions {
  fetchUserInfo: (userId: string) => Promise<void>;
  fetchUserSets: (userId: string) => Promise<void>;
  reset: () => void;
}

const initialState: UserState = {
  user: null,
  statistics: null,
  trophies: null,
  sets: null,
};

export const useUserStore = create<UserState & UserActions>((set) => ({
  ...initialState,
  fetchUserInfo: async (userId: string) => {
    try {
      const res = await getUserInfo(userId);
      const { user, userStats, userTrophies } = res.profile;
      set({ user, statistics: userStats, trophies: userTrophies });
    } catch (error) {
      if (isDev()) {
        set({
          user: getUserMock(),
          statistics: getUserStatsMock(),
          trophies: trophyListMock,
        });
        return;
      }
      console.error('getUserInfo error:', error);
    }
  },
  fetchUserSets: async (userId: string) => {
    try {
      const res = await getUserSets(userId);
      const { sets } = res.userSets;
      set({ sets });
    } catch (error) {
      if (isDev()) {
        set({ sets: userSetsMock });
        return;
      }
      console.error('getUserSets error:', error);
    }
  },
  reset: () => set(initialState),
}));
