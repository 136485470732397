// import { nanoid } from 'nanoid';
import { User, UserStatistics, Trophy } from './user.types';

export const getUserMock = (overrides?: Partial<User>): User => ({
  id: 'arnie-dev-test-id',
  firstName: 'Arnie',
  lastName: 'English',
  ...overrides,
});

export const getUserStatsMock = (overrides?: Partial<UserStatistics>): UserStatistics => ({
  points: 5000,
  levelVocabulary: 'Upper Intermediate',
  numLearnedCards: 10,
  ...overrides,
});

export const getUserTrophyMockGold = (overrides?: Partial<Trophy>): Trophy => ({
  number: 1,
  info: {
    version: 1,
    type: 'gold',
    title: 'Gold Name',
    name: 'Golden Day',
    url: 'https://i.imgur.com/vlP2fl9.png',
    rarity: 0.3,
  },
  ...overrides,
});

export const getUserTrophyMockSilver = (overrides?: Partial<Trophy>): Trophy => ({
  number: 2,
  info: {
    version: 1,
    type: 'silver',
    title: 'Silver Name',
    name: 'Silver Day',
    url: 'https://i.imgur.com/jPz0Zbq.png',
    rarity: 0.3,
  },
  ...overrides,
});

export const getUserTrophyMockBronze = (overrides?: Partial<Trophy>): Trophy => ({
  number: 2,
  info: {
    version: 1,
    type: 'bronze',
    title: 'Bronze Name',
    name: 'Bronze Twilight',
    url: 'https://i.imgur.com/ZvsvRBe.png',
    rarity: 0.2,
  },
  ...overrides,
});

const trophyMock = getUserTrophyMockGold();

export const trophyListMock = [
  trophyMock,
  getUserTrophyMockGold({
    info: { ...trophyMock.info, version: 3 },
  }),
  getUserTrophyMockGold({
    info: { ...trophyMock.info, version: 1 },
  }),
  getUserTrophyMockGold({
    info: { ...trophyMock.info, version: 4 },
  }),
  getUserTrophyMockSilver(),
  getUserTrophyMockBronze(),
];

export const userSetsMock = [
  {
    id: 'dc9e2479-ab10-438b-8d7d-c4e843cc7502',
    name: '📓 My study set',
    progress: 78.08,
    level: 'mixed',
    totalCards: 52,
    numLearnedCards: 30,
    isActive: true,
    owner: {
      id: '1bdb17a7-b078-45bd-a89f-aaaf24dad42c',
      firstName: 'Dmytro',
      lastName: 'K',
    },
  },
  {
    id: '86305587-c16b-4a54-beb3-dce2a96c32fc',
    name: 'Gerunds and infinit2',
    progress: 0,
    level: 'mixed',
    totalCards: 0,
    numLearnedCards: 0,
    isActive: false,
    owner: {
      id: '1bdb17a7-b078-45bd-a89f-aaaf24dad42c',
      firstName: 'Dmytro',
      lastName: 'K',
    },
  },
  {
    id: 'ce705f23-7466-41ab-aacd-e0845ac51e3a',
    name: 'Gerunds and infiniti',
    progress: 0,
    level: 'mixed',
    totalCards: 0,
    numLearnedCards: 0,
    isActive: false,
    owner: {
      id: '1bdb17a7-b078-45bd-a89f-aaaf24dad42c',
      firstName: 'Dmytro',
      lastName: 'K',
    },
  },
  {
    id: '7de5498a-daf1-452d-86b4-03cbdbeabf0b',
    name: 'Все для кухни',
    progress: 97.2,
    level: 'upper-intermediate',
    totalCards: 25,
    numLearnedCards: 22,
    isActive: false,
    owner: {
      id: 'd332fe94-fc6f-4cd3-b36d-a736069e9ba2',
      firstName: 'Arnie',
      lastName: undefined,
    },
  },
  {
    id: '8168d259-64e5-495e-8731-6b53ceedd4a6',
    name: 'Начальный набор Inter',
    progress: 19.33,
    level: 'intermediate',
    totalCards: 15,
    numLearnedCards: 0,
    isActive: false,
    owner: {
      id: 'd332fe94-fc6f-4cd3-b36d-a736069e9ba2',
      firstName: 'Arnie',
      lastName: undefined,
    },
  },
  {
    id: 'b3252f0d-c3cb-4aef-bf7c-7136f33463fc',
    name: 'Виды спорта',
    progress: 94.67,
    level: 'upper-intermediate',
    totalCards: 15,
    numLearnedCards: 13,
    isActive: false,
    owner: {
      id: 'd332fe94-fc6f-4cd3-b36d-a736069e9ba2',
      firstName: 'Arnie',
      lastName: undefined,
    },
  },
  {
    id: '05be36b2-7184-489b-8656-d377a5570e89',
    name: 'Части тела',
    progress: 82,
    level: 'intermediate',
    totalCards: 25,
    numLearnedCards: 12,
    isActive: false,
    owner: {
      id: 'd332fe94-fc6f-4cd3-b36d-a736069e9ba2',
      firstName: 'Arnie',
      lastName: undefined,
    },
  },
  {
    id: 'f223e1e3-4343-4aab-8d70-4ccff4084223',
    name: 'The city',
    progress: 97.5,
    level: 'intermediate',
    totalCards: 12,
    numLearnedCards: 9,
    isActive: false,
    owner: {
      id: 'd332fe94-fc6f-4cd3-b36d-a736069e9ba2',
      firstName: 'Arnie',
      lastName: undefined,
    },
  },
  {
    id: 'a5b62eab-3403-476b-991a-743c699d7517',
    name: 'Budget Travelling',
    progress: 99,
    level: 'intermediate',
    totalCards: 10,
    numLearnedCards: 9,
    isActive: false,
    owner: {
      id: 'd332fe94-fc6f-4cd3-b36d-a736069e9ba2',
      firstName: 'Arnie',
      lastName: undefined,
    },
  },
  {
    id: '17c917bc-b26c-4795-bc29-d71283cb720f',
    name: 'Продукты питания',
    progress: 12.27,
    level: 'pre-intermediate',
    totalCards: 22,
    numLearnedCards: 0,
    isActive: false,
    owner: {
      id: 'd332fe94-fc6f-4cd3-b36d-a736069e9ba2',
      firstName: 'Arnie',
      lastName: undefined,
    },
  },
  {
    id: '42c0b39b-0c01-4cad-b1cf-a0ce9dcddde3',
    name: 'Домашние животные',
    progress: 42.67,
    level: 'elementary',
    totalCards: 15,
    numLearnedCards: 0,
    isActive: false,
    owner: {
      id: 'd332fe94-fc6f-4cd3-b36d-a736069e9ba2',
      firstName: 'Arnie',
      lastName: undefined,
    },
  },
  {
    id: '538a7352-b0f6-40e3-b655-3a8f9d44bad5',
    name: 'Начальный набор Elementary',
    progress: 0,
    level: 'elementary',
    totalCards: 15,
    numLearnedCards: 0,
    isActive: false,
    owner: {
      id: 'd332fe94-fc6f-4cd3-b36d-a736069e9ba2',
      firstName: 'Arnie',
      lastName: undefined,
    },
  },
  {
    id: '316b2ed9-5b77-4551-a657-af155143758f',
    name: 'Погода',
    progress: 2,
    level: 'elementary',
    totalCards: 15,
    numLearnedCards: 0,
    isActive: false,
    owner: {
      id: 'd332fe94-fc6f-4cd3-b36d-a736069e9ba2',
      firstName: 'Arnie',
      lastName: undefined,
    },
  },
  {
    id: 'd2e1b0e4-7603-4bf4-8cc1-6ae7533d2e06',
    name: 'Начальный набор Pre',
    progress: 99.33,
    level: 'pre-intermediate',
    totalCards: 15,
    numLearnedCards: 14,
    isActive: false,
    owner: {
      id: 'd332fe94-fc6f-4cd3-b36d-a736069e9ba2',
      firstName: 'Arnie',
      lastName: undefined,
    },
  },
  {
    id: '9f48bcce-cdf1-440d-bab1-27acff41455a',
    name: 'Начальный набор Upper',
    progress: 18,
    level: 'upper-intermediate',
    totalCards: 15,
    numLearnedCards: 0,
    isActive: false,
    owner: {
      id: 'd332fe94-fc6f-4cd3-b36d-a736069e9ba2',
      firstName: 'Arnie',
      lastName: undefined,
    },
  },
  {
    id: '515be1e0-5db0-4329-b368-212ac50bc22e',
    name: 'Фразовые глаголы с get',
    progress: 67.33,
    level: 'intermediate',
    totalCards: 15,
    numLearnedCards: 6,
    isActive: false,
    owner: {
      id: 'd332fe94-fc6f-4cd3-b36d-a736069e9ba2',
      firstName: 'Arnie',
      lastName: undefined,
    },
  },
  {
    id: 'ed325cba-7161-496e-b850-264e3a128127',
    name: 'Части тела',
    progress: 100,
    level: 'pre-intermediate',
    totalCards: 19,
    numLearnedCards: 19,
    isActive: false,
    owner: {
      id: 'd332fe94-fc6f-4cd3-b36d-a736069e9ba2',
      firstName: 'Arnie',
      lastName: undefined,
    },
  },
  {
    id: '510738db-0f75-4d17-b20c-5d7544600205',
    name: 'Профессии',
    progress: 100,
    level: 'pre-intermediate',
    totalCards: 20,
    numLearnedCards: 20,
    isActive: false,
    owner: {
      id: 'd332fe94-fc6f-4cd3-b36d-a736069e9ba2',
      firstName: 'Arnie',
      lastName: undefined,
    },
  },
  {
    id: 'c25629aa-c7ca-4606-b7b1-f559c46797ba',
    name: 'People around you',
    progress: 89.33,
    level: 'intermediate',
    totalCards: 15,
    numLearnedCards: 11,
    isActive: false,
    owner: {
      id: 'd332fe94-fc6f-4cd3-b36d-a736069e9ba2',
      firstName: 'Arnie',
      lastName: undefined,
    },
  },
];
