import { Stack, Fab, Typography, Menu, MenuItem, IconButton } from '@mui/material';
import { ChevronLeft, Settings, EditOutlined, Delete } from '@mui/icons-material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/routes.config';
import { DeleteStudySetDialog } from './DeleteStudySetDialog';
import { UpdateStudySetDialog } from './UpdateStudySetDialog';

interface DetailsHeaderProps {
  isOwner: boolean;
  isParticipant: boolean;
  name: string;
}

export function DetailsHeader({ isOwner, isParticipant, name }: DetailsHeaderProps) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Fab
        onClick={() => navigate(ROUTES.BACK)}
        size="small"
        color="secondary"
        sx={{ minWidth: 40 }}
      >
        <ChevronLeft />
      </Fab>
      <Typography variant="h2" color="primary" align="center">
        {name}
      </Typography>
      <Stack direction="row" spacing={1}>
        {isOwner ? (
          <Fab size="small" color="secondary" onClick={handleMenuOpen}>
            <Settings />
          </Fab>
        ) : isParticipant ? (
          <IconButton
            size="small"
            sx={{ border: '1px solid', borderColor: 'error.main' }}
            onClick={() => setShowDeleteDialog(true)}
          >
            <Delete color="error" />
          </IconButton>
        ) : (
          <span className="stub" />
        )}
      </Stack>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            setShowEditDialog(true);
            setAnchorEl(null);
          }}
        >
          <EditOutlined sx={{ mr: 2 }} />
          <Typography variant="body1">{t('common.edit')}</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowDeleteDialog(true);
            setAnchorEl(null);
          }}
        >
          <Delete color="error" sx={{ mr: 2 }} />
          <Typography variant="body1" color="error">
            {t('common.delete')}
          </Typography>
        </MenuItem>
      </Menu>
      <DeleteStudySetDialog
        isParticipant={isParticipant}
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      />
      <UpdateStudySetDialog open={showEditDialog} onClose={() => setShowEditDialog(false)} />
    </Stack>
  );
}
