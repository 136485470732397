import { Box, Card, CardContent, CardActionArea, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/routes.config';
import { ProfileRouteParams } from 'routes/routes.types';
import { UserSetCard } from 'stores/user';
import { ProgressLine } from 'components/commons';

interface SetItemProps {
  set: UserSetCard;
  isOwner: boolean;
}

export function SetItem({ set, isOwner }: SetItemProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = useParams() as ProfileRouteParams;

  return (
    <Card
      sx={{
        minWidth: 275,
        position: 'relative',
        borderBottom: 0,
        borderLeft: 1,
        bgcolor: 'secondary.main',
      }}
    >
      <CardActionArea onClick={() => navigate(ROUTES.STUDY_SET(userId, set.id))}>
        <CardContent
          sx={{
            p: 1,
            minHeight: isOwner ? 100 : 46,
            pb: '0.5rem !important',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            {set.isActive && isOwner && <span className="active-point pulse" />}
            {isOwner ? (
              <Typography variant="h4" pr={2}>
                {set.name}
              </Typography>
            ) : (
              <>
                <Typography variant="h4" align="left">
                  {set.name}
                </Typography>
                <Typography variant="body1" align="left" color="text.secondary">
                  {t('profile_sets.term', { count: set.totalCards })}
                </Typography>
              </>
            )}
            {isOwner && (
              <Typography sx={{ fontSize: 14 }} color="text.secondary">
                {t('profile_sets.learned_status', {
                  learnedAmount: set.numLearnedCards,
                  totalAmount: set.totalCards,
                })}
              </Typography>
            )}
          </Box>
          {isOwner && <ProgressLine colored size="small" progress={set.progress} />}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
