export const STUDY_SET_CARDS_LIMIT = 50;
export const STUDY_SETS_LIMIT = 5;
export const BASE_BG_COLOR = '#1b1930';

export const COMPLEXITY = [
  { title: 'Elementary', level: 'elementary' },
  { title: 'Pre Intermediate', level: 'pre-intermediate' },
  { title: 'Intermediate', level: 'intermediate' },
  { title: 'Upper Intermediate', level: 'upper-intermediate' },
  { title: 'Mixed', level: 'mixed' },
];

export const MOBILE_PLATFORMS = ['android', 'ios'];

export const SERVICE_MSG_KEYS: { [key: string]: string } = {
  '403.2': 'service_messages.dictionary_limit',
  '403.3': 'service_messages.dictionary_items_limit',
};

export const STUDY_SET_NAME_LENGTH_LIMIT = 80;
export const TROPHY_TYPES = ['gold', 'silver', 'bronze'];
export const TROPHY_VARIANTS = ['morning', 'day', 'twilight', 'night'];
