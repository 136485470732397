import { Paper, Box, Tab } from '@mui/material';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import Grid from '@mui/material/Unstable_Grid2';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ProfileRouteParams } from 'routes/routes.types';
import { useUserStore } from 'stores/user';
import { BaseInfo, UserSets, UserTrophies } from './components';

export function Profile() {
  const [currentTab, setCurrentTab] = useState('sets');
  const { userId } = useParams() as ProfileRouteParams;
  const { t } = useTranslation();
  const { fetchUserInfo, fetchUserSets, reset } = useUserStore();

  useEffect(() => {
    (async () => {
      await fetchUserInfo(userId);
      await fetchUserSets(userId);
    })();
    return reset;
  }, [userId]);

  const tabs = [
    { content: <UserSets />, id: 'sets' },
    { content: <UserTrophies />, id: 'trophies' },
  ];

  return (
    <Box sx={{ mt: { xs: 1, sm: 2 } }}>
      <Grid container spacing={2}>
        <BaseInfo />
        <Grid xs={12}>
          <TabContext value={currentTab}>
            <Paper>
              <TabList onChange={(_, value) => setCurrentTab(value)}>
                {tabs.map(({ id }) => (
                  <Tab key={id} label={t(`profile.tab_${id}`)} value={id} />
                ))}
              </TabList>
            </Paper>
            {tabs.map(({ id, content }) => (
              <TabPanel key={id} value={id} sx={{ p: 0, pt: 2 }}>
                {content}
              </TabPanel>
            ))}
          </TabContext>
        </Grid>
      </Grid>
    </Box>
  );
}
