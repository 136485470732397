import { Button, Box, Paper, Skeleton, Fab, useMediaQuery } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useStudySetStore,
  useIsCurrentUserOwner,
  useIsCurrentUserParticipant,
} from 'stores/studySet';
import { StudySetRouteParams } from 'routes/routes.types';
import { ProgressLine } from 'components/commons';
import { CollapsedList } from './Partials/CollapsedList';
import { DetailsHeader } from './Partials/DetailsHeader';

export function DetailsBlock() {
  const { userId } = useParams() as StudySetRouteParams;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { studySet, isDetailsExpanded, activate, toggleDetails } = useStudySetStore();
  const isCurrentUserOwner = useIsCurrentUserOwner();
  const isCurrentUserParticipant = useIsCurrentUserParticipant(userId);

  const handleActivate = async () => {
    setLoading(true);
    if (studySet?.id) await activate(studySet.id);
    setLoading(false);
  };

  return (
    <Paper sx={{ p: 2 }}>
      {studySet ? (
        <>
          <DetailsHeader
            isOwner={isCurrentUserOwner}
            isParticipant={isCurrentUserParticipant}
            name={studySet.name}
          />
          {(isCurrentUserOwner || isCurrentUserParticipant) && (
            <>
              <CollapsedList
                show={isDetailsExpanded}
                description={studySet.description}
                owner={studySet.owner}
                isOwner={isCurrentUserOwner}
                isActive={studySet.isActive}
                isPublic={studySet.isPublic}
              />
              {!studySet.isActive && studySet.cards.length ? (
                <Grid container sx={{ my: 2 }}>
                  <Grid xs={10} sm={3}>
                    <LoadingButton
                      loading={loading}
                      fullWidth
                      variant="contained"
                      onClick={handleActivate}
                    >
                      {t('set.activate')}
                    </LoadingButton>
                  </Grid>
                  <Grid xs={2} sm={9} sx={{ textAlign: 'right' }}>
                    <Fab size="small" color="secondary" onClick={toggleDetails}>
                      {isDetailsExpanded ? <ExpandLess /> : <ExpandMore />}
                    </Fab>
                  </Grid>
                </Grid>
              ) : (
                <Box sx={{ mb: 2, mt: 1, display: 'flex', justifyContent: 'center' }}>
                  <Button
                    fullWidth={isMobile}
                    variant="contained"
                    color="secondary"
                    onClick={toggleDetails}
                  >
                    {isDetailsExpanded ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                </Box>
              )}
              <ProgressLine inline colored progress={studySet.progress} />
            </>
          )}
        </>
      ) : (
        <>
          <Skeleton sx={{ py: 2 }} />
          <Skeleton sx={{ py: 2 }} />
          <Skeleton sx={{ py: 2 }} />
        </>
      )}
    </Paper>
  );
}
