import { create } from 'zustand';
import {
  createStudySet,
  getStudySet,
  activateStudySet,
  deleteStudySet,
  removeStudySet,
  updateStudySet,
} from 'graphql/studySet';
import { isDev } from 'utils';
import { StudySet, UpateStudySetData } from './studySet.types';
import { getStudySetMock } from './studySet.mocks';
import { useAppStore } from 'stores/app';

interface StudySetState {
  studySet: StudySet | null;
  isDetailsExpanded: boolean;
}

interface StudySetActions {
  create: (name: string, visibility: string) => Promise<string | null>;
  fetch: (setId: string) => Promise<void>;
  activate: (setId: string) => Promise<void>;
  delete: (setId: string) => Promise<boolean>;
  remove: (setId: string) => Promise<boolean>;
  update: (data: UpateStudySetData) => Promise<boolean>;
  refresh: () => void;
  toggleDetails: () => void;
  reset: () => void;
}

const initialState: StudySetState = {
  isDetailsExpanded: false,
  studySet: null,
};

export const useStudySetStore = create<StudySetState & StudySetActions>((set, get) => ({
  ...initialState,
  create: async (name, visibility) => {
    try {
      const { createUserSet } = await createStudySet(name, visibility);
      if (createUserSet?.errors?.length) {
        const [error] = createUserSet.errors;
        useAppStore.getState().setServiceMessageCode(error.code);
        return null;
      }
      useAppStore.getState().setNotificationBar('success');
      return createUserSet.id;
    } catch (error) {
      console.error('createStudySet error:', error);
      useAppStore.getState().setNotificationBar('error');
      return null;
    }
  },
  fetch: async (setId) => {
    try {
      const { userSet } = await getStudySet(setId);
      set((state) => {
        if (state.studySet !== userSet) {
          return { studySet: userSet };
        }
        return state;
      });
    } catch (error) {
      if (isDev()) {
        set({ studySet: getStudySetMock() });
        return;
      }
      console.error('getStudySet error:', error);
    }
  },
  refresh: () => {
    const { fetch, studySet } = get();
    if (studySet?.id) fetch(studySet.id);
  },
  activate: async (setId) => {
    try {
      const { setUserSetActive } = await activateStudySet(setId);
      if (setUserSetActive.isSuccess) {
        set((state) => ({
          studySet: state.studySet ? { ...state.studySet, isActive: true } : null,
        }));
        useAppStore.getState().setNotificationBar('success');
      }
    } catch (error) {
      console.error('activateStudySet error:', error);
      useAppStore.getState().setNotificationBar('error');
    }
  },
  delete: async (setId) => {
    try {
      const { deleteSet } = await deleteStudySet(setId);
      if (deleteSet.isSuccess) {
        useAppStore.getState().setNotificationBar('success');
        return true;
      }
      return false;
    } catch (error) {
      console.error('deleteStudySet error:', error);
      useAppStore.getState().setNotificationBar('error');
      return false;
    }
  },
  remove: async (setId) => {
    try {
      const { removeSet } = await removeStudySet(setId);
      if (removeSet.isSuccess) {
        useAppStore.getState().setNotificationBar('success');
        return true;
      }
      return false;
    } catch (error) {
      console.error('removeStudySet error:', error);
      useAppStore.getState().setNotificationBar('error');
      return false;
    }
  },
  update: async (data) => {
    try {
      const { updateUserSet } = await updateStudySet(data);
      if (updateUserSet.isSuccess) {
        useAppStore.getState().setNotificationBar('success');
        get().refresh();
        return true;
      }
      return false;
    } catch (error) {
      console.error('updateStudySet error:', error);
      useAppStore.getState().setNotificationBar('error');
      return false;
    }
  },
  toggleDetails: () => set((state) => ({ isDetailsExpanded: !state.isDetailsExpanded })),
  reset: () => set(initialState),
}));
