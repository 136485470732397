import { useAuthStore } from 'stores/auth';
import { useUserStore } from 'stores/user';
import { STUDY_SET_CARDS_LIMIT } from 'constants/index';
import { useStudySetStore } from './studySet.store';

export const useIsCurrentUserOwner = (): boolean => {
  const { user } = useAuthStore();
  const { studySet } = useStudySetStore();
  return user?.id === studySet?.owner.id;
};

export const useIsCurrentUserParticipant = (userId: string): boolean => {
  const { user: authUser } = useAuthStore();
  const { sets } = useUserStore();
  const { studySet } = useStudySetStore();
  if (userId !== authUser?.id) return false;
  return !!(authUser?.id !== studySet?.owner.id && sets?.some(({ id }) => studySet?.id === id));
};

export const useCardsLimitation = (): ((additionalCards?: number) => boolean) => {
  const { studySet } = useStudySetStore();
  const cardsAmount = studySet?.cards?.length ?? 0;
  return (additionalCards = 0) => cardsAmount + additionalCards < STUDY_SET_CARDS_LIMIT;
};
