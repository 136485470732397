import { graphql } from 'services/graphql';

export const getUserInfoQuery = graphql`
  query profile($userId: String!) {
    profile(userId: $userId) {
      user {
        id
        firstName
        lastName
        username
      }
      userStats {
        points
        levelVocabulary
        numLearnedCards
      }
      userTrophies {
        number
        info {
          version
          type
          title
          name
          url
          rarity
        }
      }
    }
  }
`;

export const getUserSetsQuery = graphql`
  query userSets($userId: String!) {
    userSets(userId: $userId) {
      sets {
        id
        name
        progress
        level
        totalCards
        numLearnedCards
        isActive
        owner {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
