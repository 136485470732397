import { User } from 'stores/user';

export enum Visibility {
  private = 'private',
  public = 'public',
}

export interface StudySetCard {
  id: string;
  terms: string[];
  relatedTerms: string[];
  hasDefinitions: boolean;
  definitions: string[];
  hasExamples: boolean;
  examples: string[];
  learningLevel: number;
}

export interface StudySet {
  id: string;
  description: string;
  isActive: boolean;
  isPublic: boolean;
  level: string;
  name: string;
  numLearnedCards: number;
  progress: number;
  totalCards: number;
  owner: User;
  cards: StudySetCard[];
}

export interface UpateStudySetData {
  userSetId: string;
  title?: string;
  description?: string;
  visibility?: string;
  level?: string;
}
